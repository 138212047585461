import request from '@/utils/request'

//获取省

export function getProvince() {
  return request({
    url: '/city/getCityAll',
    method: 'get'
  })
}

//城市
export function getByFatherIdt(query) {
  return request({
    url: '/city/getAllCity',
    method: 'get',
    params: query
  })
}
//根据code值查询
export function getCode(query) {
  return request({
    url: '/code/getCode',
    method: 'get',
    params: query
  })
}
//获取省市区
export function getCityAll() {
  return request({
    url: '/china/getCityAll',
    method: 'get'
  })
}

//根据企业全称查询数据系统数据
export function updateWechatGroupStatus(query) {
  return request({
    url: '/serverCompany/updateWechatGroupStatus',
    method: 'get',
    params: query
  })
}
//
export function pushPlanInformation(data) {
  return request({
    url: '/serverInformation/pushPlanInformation',
    method: 'post',
    data
  })
}
