<template>
  <div class="user">
    <div class="container">
      <!-- 顶部卡片 -->
      <el-card>
        <el-form ref="form" :model="queryInfo" label-width="120px">
          <el-form-item label="用户类别：">
            <el-radio-group v-model="queryInfo.userType" @change="changeSearchangeSearch">
              <el-radio-button :label="item.lable" v-for="(item, index) in userType" :key="index">{{
                item.name
              }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="有无名片：">
            <el-radio-group v-model="queryInfo.idCards" @change="search">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button :label="1">有名片</el-radio-button>
              <el-radio-button :label="2">无名片</el-radio-button>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="用户类型：">
            <el-radio-group v-model="queryInfo.userLevel" @change="changeSearchangeSearch">
              <el-radio-button :label="null">全部</el-radio-button>
              <el-radio-button :label="1">游客用户</el-radio-button>
              <el-radio-button :label="2">普通用户</el-radio-button>
              <el-radio-button :label="3">企业用户</el-radio-button>
              <el-radio-button :label="4">个人会员</el-radio-button>
              <el-radio-button :label="5">RPA专家用户</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="用户职位：">
            <el-radio-group v-model="queryInfo.position" @change="changeSearchangeSearch">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button
                :label="item.codeId"
                v-for="(item, index) in userPosition"
                :key="index"
                >{{ item.codeName }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <div style="display: flex">
            <el-form-item label="创建日期：">
              <el-radio-group v-model="queryInfo.createTime" @change="changeSearchangeSearch">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="今日">今日</el-radio-button>
                <el-radio-button label="一周内">一周内</el-radio-button>
                <el-radio-button label="一月内">一月内</el-radio-button>
                <el-radio-button label="三月内">三月内</el-radio-button>
                <el-radio-button label="一年内">一年内</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="" label-width="0px">
              <el-date-picker
                @change="createTimechange"
                v-model="createTimeList"
                type="daterange"
                unlink-panels
                start-placeholder="请选择日期"
                end-placeholder="请选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <el-form-item label="账号周期：">
            <el-date-picker
              @change="timechange"
              v-model="timeList"
              type="daterange"
              unlink-panels
              start-placeholder="请选择日期"
              end-placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="所在城市：">
            <!-- <city-select
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              :district="queryInfo.district"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
              @searchDistrict="searchDistrict"
            /> -->
            <el-select v-model="queryInfo.province" placeholder="请选择省份" @change="citychange">
              <el-option :value="''" label="全部"></el-option>
              <el-option
                v-for="item in operatingList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <div style="display: inline-block; width: 10px"></div>
            <el-select v-model="queryInfo.city" placeholder="请选择市" @change="districtchange">
              <el-option :value="''" label="全部"></el-option>
              <el-option
                v-for="item in operatingLists"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <div style="display: inline-block; width: 10px"></div>
            <el-select v-model="queryInfo.district" placeholder="请选择区" @change="search">
              <el-option :value="''" label="全部"></el-option>
              <el-option
                v-for="item in districtList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-card>
      <!-- 底部卡片 -->
      <el-card style="margin-top: 20px">
        <div class="search" style="width: 100%; display: flex; justify-content: space-between">
          <div style="display: flex; align-items: flex-end">
            <el-button plain @click="exportExcel">批量导出</el-button>
            <el-button plain style="margin-right: 5px" @click="checkClick">批量发短信</el-button>
            <el-checkbox v-model="ischeck" @change="clcikChecked"
              >只查看申请企业会员用户</el-checkbox
            >
          </div>
          <div class="search_right">
            <el-input
              placeholder="请使用手机号码，企业全称、用户名称搜索"
              v-model="queryInfo.queryConditions"
              class="input-with-select"
              clearable
            >
              <el-button slot="append" @click="search">搜索</el-button>
            </el-input>
          </div>
        </div>
        <!-- 表单区域 -->
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          class="table_data"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
          :data="tableData"
          ref="multipleTable"
          style="width: 100%"
          tooltip-effect="dark"
          :row-key="getRowKey"
          @sort-change="changeSort"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
          <el-table-column prop="id" label="编号" align="center" width="80"> </el-table-column>
          <el-table-column label="微信头像" align="center" width="100">
            <template>
              <img
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
                class="img"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column prop="realName" label="姓名" align="center" width="80">
            <template v-slot="{ row }">
              <div style="display: flex; align-items: flex-end">
                <div>
                  <span v-if="row.realName">{{ row.realName }}</span>
                  <span v-else>{{ row.nickName }}</span>
                </div>
                <div
                  v-if="row.messageStatus == '1'"
                  style="position: relative; top: 2px; left: 3px"
                >
                  <img
                    width="13px"
                    height="13px"
                    src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Vectorryio.png"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="性别" align="center" width="60">
            <template v-slot="{ row }">
              <div class="sex" v-if="row.sex == '1'">男</div>
              <div class="sex" v-else>女</div>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="position" label="职位" align="center"> </el-table-column>
          <el-table-column prop="companyFullName" label="企业全称" align="center">
          </el-table-column>

          <el-table-column prop="address" label="所在城市" align="center"> </el-table-column>
          <el-table-column label="用户类别" align="center" width="100">
            <template v-slot="{ row }">
              <div class="type_can" v-if="row.wechatUserFlag == '1'">
                <em></em>
                <span>智参用户</span>
              </div>
              <div class="type_xuan" v-else>
                <em></em>
                <span>智选用户</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="applyStatus" label="用户类型 " align="center">
            <template v-slot="{ row }">
              <div style="display: flex; justify-content: center">
                <div class="ykuser" v-if="row.userType == 1">游客用户</div>
                <div class="formal" v-if="row.userType == 2">普通用户</div>
                <div class="tourist" v-if="row.userType == 3">企业用户</div>
               
                <div class="per_color" v-if="row.userType == 4">个人会员</div>
                <div class="jin_color" v-if="row.userType == 5">RPA专家用户</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="businessCardList" label="名片" align="center">
            <template v-slot="{ row }">
              <div v-if="row.businessCardList">
                <div class="img_list" v-for="(item, index) in row.businessCardList" :key="index">
                  <el-image
                    style="width: 70px; height: 40px"
                    :src="item"
                    :preview-src-list="row.businessCardList"
                  ></el-image>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="loginCount" label="登录次数" width="100" sortable align="center">
          </el-table-column>
          <el-table-column prop="ipCity" label="IP/城市名" align="center" width="150">
            <template v-slot="{ row }">
              <div class="time_box" v-for="(itm, index) in newIpCity(row)" :key="index">
                {{ itm }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            width="150"
            label="最近登录时间"
            sortable
            align="center"
          >
            <template v-slot="{ row }">
              <div class="time_box">
                <div>{{ row.updateTime | date }}</div>
                <div>{{ row.updateTime | time }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { getCode } from '@/api/common'
import { serverApiUserPage, userLoginPushMessage, userDataExport } from '@/api/user'
import { pagination } from '@/mixins/pagination'
// import CitySelect from '../../../components/CitySelect.vue'
import { getCityAll } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  applyStatus: '', //申请状态;1是待审核，2是已审核
  beginTime: '', //账号开始周期
  city: '', //所在地区
  deadline: '', //账号结束周期
  formalUser: '', //是否是正式用户;1是正式用户，2非正式用户,全部传''
  orderByParam: 'update_time DESC', //排序字段+ DESC或ASC
  position: '', //职位;1= CEO, 2 = CIO, 3=COO,4=IT负责人,5=项目负责人,6=其他
  province: '', //所在省份
  district: '',
  queryConditions: '', //查询条件
  wechatUserFlag: '', //小程序用户标识；1是智参，2是智选,''是全部
  idCards: '',
  companyInfo: '',
  userType: '',
  userLevel: null, //用户等级;null是全部 1是游客用户，2是普通用户，3是企业用户，4是个人会员，5是企业会员
  orderType: '',
  createTime: '', //创建日期 全部 今日 一周内 一年内
  createEndTime: '', //创建结束时间
  createStartTime: '' //创建开始时间
})
export default {
  // components: { CitySelect },
  name: 'User',
  mixins: [pagination],
  data() {
    return {
      // 遮罩层
      loading: true,
      tableData: [], //表格数据
      queryInfo: { ...defaultQueryInfo },
      userType: [
        {
          name: '全部',
          lable: ''
        },
        {
          name: '智参用户',
          lable: '1'
        },
        {
          name: '智选用户',
          lable: '2'
        }
      ], //用户类型

      userPosition: [], //用户职位：
      ids: [],
      //账号周期
      timeList: [],
      //创建时间
      createTimeList: [],
      ischeck: false, //只查看申请企业会员用户
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [] //区
    }
  },
  created() {
    this.incity()
    //页面加载完成  调取数据
    this.search()
    //获取code表 type等于2用户职位
    this.getUserPosition()
  },
  methods: {
    //获取用户职位
    async getUserPosition() {
      const { data: res } = await getCode({ codeType: '002' })
      if (res.resultCode === 200) {
        this.userPosition = res.data
      }
    },
    //批量导出
    async exportExcel() {
      this.loading = true
      const res = await userDataExport(this.ids)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `用户数据${nowtime}.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        delete this.queryInfo.ids
        this.search()
        this.$refs.multipleTable.clearSelection()
        this.isIndeterminate = false
        this.checkAll = false
      } else {
        this.$message.error('下载失败!')
      }
    },
    //选择时间
    timechange(val) {
      if (val) {
        this.queryInfo.beginTime = val[0]
        this.queryInfo.deadline = val[1]
        this.queryInfo.pageNum = 1
        this.search()
      } else {
        this.queryInfo.beginTime = null
        this.queryInfo.deadline = null
        this.queryInfo.pageNum = 1
        this.search()
      }
    },
    //创建时间
    createTimechange(val) {
      if (val) {
        this.queryInfo.createStartTime = val[0]
        this.queryInfo.createEndTime = val[1]
        this.queryInfo.pageNum = 1
        this.search()
      } else {
        this.queryInfo.createStartTime = null
        this.queryInfo.createEndTime = null
        this.queryInfo.pageNum = 1
        this.search()
      }
    },
    //只查看申请企业会员用户
    clcikChecked(val) {
      if (val) {
        this.queryInfo.orderType = '1'
      } else {
        this.queryInfo.orderType = '0'
      }
      this.search()
    },
    //批量发短信
    checkClick() {
      const that = this
      const ids = this.ids

      this.$alert('是否确认发送短信？')
        .then(function () {
          return userLoginPushMessage(ids)
        })
        .then(() => {
          that.search()
          that.ids = []
          that.$refs.multipleTable.clearSelection()
          that.$message.success('发送成功')
        })
        .catch(() => {})
    },
    getRowKey(row) {
      return row.id
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    //城市
    async incity() {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //选择城市
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.queryInfo.pageNum = 1
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.queryInfo.pageNum = 1
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfo.pageNum = 1
              this.queryInfo.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.search()
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    //获取表格页面数据
    async search() {
      this.loading = true
      const { data: res } = await serverApiUserPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        // console.log("ip城市",this.tableData)
        this.total = res.data.total
        this.loading = false
      } else {
        this.loading = false
      }
    },

    //排序
    changeSort(val) {
      //val里面返回prop和order

      if (val.prop == 'loginCount') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'login_count ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'login_count DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'update_time DESC'
          this.search()
        }
      } else if (val.prop == 'updateTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'update_time ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'update_time DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'update_time DESC'
          this.search()
        }
      }
    },

    // 分割ip地址
    newIpCity(val) {
      const ipCityList = val.ipCity ? val.ipCity.split('/') : null
      const ipCityList0 = ipCityList ? ipCityList[0] : null
      const ipCityList1 = ipCityList ? ipCityList[1] : null
      return [ipCityList0, ipCityList1]
    },

    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  width: 100%;
  overflow: hidden;

  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }

  .container {
    height: calc(100vh - 150px);
    overflow: auto;
  }

  .table_data {
    .img {
      width: 42px;
      height: 42px;
      display: block;
      margin: 0 auto;
      border-radius: 4px;
    }

    .type_can {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #ff7d18;
        border-radius: 50%;
      }

      span {
        color: #ff7d18;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .type_xuan {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #001aff;
        border-radius: 50%;
      }

      span {
        color: #001aff;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .formal {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(78, 147, 251, 0.1);
      color: #4e93fb;
      border-radius: 2px;
    }
    .jin_color {
      width: 121px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f4e2ab;
      color: #fff;
      border-radius: 2px;
    }
    .per_color {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e6a23c;
      color: #fff;
      border-radius: 2px;
    }

    .tourist {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 125, 24, 0.1);
      color: #ff7d18;
      border-radius: 2px;
    }
    .ykuser {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e5f9ea;
      color: #86e49b;
      border-radius: 2px;
    }

    .img_list {
      .new_img {
        width: 70px;
        height: 40px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }
  }

  .search {
    width: 100%;
    margin: 20px 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
  }

  .search_right {
    width: 560px;

    /deep/ .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 0;
      background-color: #448aff;
    }
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
}
</style>
