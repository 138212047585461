<template>
  <div class="EnterpriseContent">
    <div class="order_top" v-if="!tabloidId">
      <div class="storeCenter_companyinfo_top_left">
        <img v-if="companyinfo.companyLogo" :src="companyinfo.companyLogo" alt="" />
        <img v-else src="../../../assets/image/Group 3237.png" alt="" />
        <div class="storeCenter_companyinfo_top_left_flex">
          <div style="display: flex; align-companyinfos: center">
            <h5 style="display: inline-block; margin: 0; font-size: 20px">
              {{ companyinfo.companyFullName }}
            </h5>
          </div>
          <div class="storeCenter_companyinfo_top_left_flex_tag">
            <span v-if="companyinfo.labelList" style="display: flex">
              <span v-for="(o, index) in companyinfo.labelList.slice(0, 3)" :key="index">
                <span v-if="index < 3" class="office">{{ o }}</span>
              </span>
            </span>
            <el-popover placement="right" width="430" trigger="hover">
              <div
                class="hidden_label_box"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                <span
                  class="office"
                  style="
                    border: 1px solid #559eff;
                    color: #4e93fb;
                    box-sizing: border-box;
                    padding: 1px 6px;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 4px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                  "
                  v-for="(o, indexs) in companyinfo.labelList"
                  :key="indexs"
                  >{{ o }}</span
                >
              </div>
              <span
                class="office"
                slot="reference"
                v-if="companyinfo.labelList && companyinfo.labelList.length >= 3"
                >{{ companyinfo.labelList.length }}+</span
              >
            </el-popover>
          </div>
          <div class="address">
            <span style="padding-left: 0px"> {{ companyinfo.legalPerson }}</span>
            <span> {{ companyinfo.companyPhone }}</span>
            <span> {{ companyinfo.province }}-{{ companyinfo.city }}</span>
          </div>
        </div>
      </div>
      <div class="buttom_info">
        <el-button
          style="color: #146aff"
          @click="companyInfo"
          class="primary_buttom"
          type="primary"
          plain
          icon="el-icon-office-building"
          >基本信息</el-button
        >
      </div>
    </div>
    <div class="order_top" v-else>
      <div class="storeCenter_companyinfo_top_left">
        <img :src="paramWeek.cover" alt="" />
        <div class="storeCenter_companyinfo_top_left_flex">
          <div style="display: flex; align-companyinfos: center">
            <h5 style="display: inline-block; margin: 0; font-size: 20px">
              {{ paramWeek.tabloidIntro }}
            </h5>
          </div>
          <div class="storeCenter_companyinfo_top_left_flex_tag">
            <span>{{ companyinfo.companyFullName }}</span>
          </div>
          <div class="address">
            <span style="padding-left: 0px"> {{ companyinfo.legalPerson }}</span>
            <span> {{ companyinfo.companyPhone }}</span>
            <span> {{ companyinfo.province }}-{{ companyinfo.city }}</span>
            <span style="color: #4e93fb">{{ companyinfo.officialWebsite }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="form_info">
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in typeList"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === indexType ? 'active' : ''"
        >
          <span>{{ item.name }} ({{ item.num }})</span>
        </div>
      </div>
      <el-form ref="form" :model="queryInfo" label-width="90px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="资讯类别" v-if="queryInfo.type == 1">
              <el-radio-group v-model="queryInfo.informationType" @change="search">
                <el-radio-button label="null">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in informationType"
                  :key="index"
                  :label="item.id"
                  >{{ item.val }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="匹配类型">
              <el-radio-group v-model="queryInfo.matchingType" @change="search">
                <el-radio-button label="null">全部</el-radio-button>
                <el-radio-button label="1">人工算法</el-radio-button>
                <el-radio-button label="2">匹配算法</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label=" " label-width="0px">
              <div class="input-with">
                <div>
                  <el-input
                    placeholder="请输入名称"
                    v-model="queryInfo.queryConditions"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="tab_txt">
        <el-table
          ref="multipleTable"
          :data="listData"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中..."
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column
            label="编号"
            align="center"
            width="110px"
            key="foreignKey"
            prop="foreignKey"
          >
          </el-table-column>

          <el-table-column
            v-if="queryInfo.type == 1"
            label="资讯名称"
            align="center"
            width="190"
            key="informationName"
            prop="informationName"
          >
          </el-table-column>
          <el-table-column
            v-if="queryInfo.type == 2"
            label="活动名称"
            align="center"
            width="190"
            key="informationName"
            prop="informationName"
          >
          </el-table-column>
          <el-table-column
            v-if="queryInfo.type == 3"
            label="专家名称"
            align="center"
            width="190"
            key="informationName"
            prop="informationName"
          >
          </el-table-column>
          <el-table-column
            v-if="queryInfo.type == 4"
            label="报告名称"
            align="center"
            width="190"
            key="informationName"
            prop="informationName"
          >
          </el-table-column>
          <el-table-column
            v-if="queryInfo.type == 5"
            label="问题名称"
            align="center"
            width="190"
            key="informationName"
            prop="informationName"
          >
          </el-table-column>

          <el-table-column label="封面图" key="cover" prop="cover" align="center">
            <template v-slot="{ row }">
              <div class="imgbox">
                <div class="img">
                  <img width="69" height="48" :src="row.cover" alt="" />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="queryInfo.type == 1"
            key="informationType"
            label="资讯类型"
            align="center"
            width="100"
            prop="informationType"
          >
            <template v-slot="{ row }">
              <div class="correlationBox">
                <div class="correlation" v-if="row.informationType && row.informationType <= 9">
                  {{ informationType[row.informationType - 1].val }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" key="labels" prop="labels" label="标签" width="200px">
            <template v-slot="{ row }">
              <span v-for="(o, index) in row.labels" :key="index">
                <span v-if="index < 4 && o != ''" class="span_tag">#{{ o }}</span>
              </span>
              <el-popover placement="bottom" width="230" trigger="hover">
                <div
                  class="hidden_label_box"
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    flex-wrap: wrap;
                  "
                >
                  <span
                    class="span_tag"
                    v-for="(o, index) in row.labels && row.labels.length
                      ? row.labels.filter((el, index) => index > 3)
                      : []"
                    :key="index"
                    style="
                      font-family: 'PingFang SC';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      color: #4e93fb;
                      margin-right: 10px;
                    "
                    >#{{ o }}</span
                  >
                </div>
                <span
                  style="color: #4e93fb"
                  slot="reference"
                  v-if="row.labels && row.labels.length > 4"
                  >...{{ row.labels.length - 4 }}</span
                >
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column key="brief" prop="brief" label="一句话描述" width="220" align="center">
            <template v-slot="{ row }">
              <span>{{ row.brief }}</span>
            </template>
          </el-table-column>

          <el-table-column
            label="分享"
            width="170"
            key="shareResultList"
            prop="shareResultList"
            align="center"
          >
            <template v-slot="{ row }">
              <div v-if="row.shareResultList">
                <div :class="[row.spread ? 'shareResultList' : 'spreadwrap']">
                  <div
                    class="tooltip_box"
                    v-for="(item, index) in row.shareResultList"
                    :key="index"
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        <div>{{ item.shareName }}</div>
                        <div style="margin-top: 5px">
                          <span>{{ item.position }}|{{ item.phone }}</span>
                        </div>
                      </div>
                      <el-button type="text">
                        {{ item.shareName }}
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="row.shareResultList.length > 2">
                  <div v-show="row.spread == false" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary" plain
                      >展开<el-icon class="el-icon-arrow-down"></el-icon
                    ></el-button>
                  </div>
                  <div v-show="row.spread == true" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary"
                      >收起<el-icon class="el-icon-arrow-up"></el-icon
                    ></el-button>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            width="170"
            label="报名"
            prop="activityResultList"
            key="activityResultList"
            v-if="queryInfo.type == 2"
            align="center"
          >
            <template v-slot="{ row }">
              <div v-if="row.activityResultList">
                <div :class="[row.spread ? 'shareResultList' : 'spreadwrap']">
                  <div
                    class="tooltip_box"
                    v-for="(item, index) in row.activityResultList"
                    :key="index"
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        <div>{{ item.applyName }}</div>
                        <div style="margin-top: 5px">
                          <span>{{ item.position }}|{{ item.phone }}</span>
                        </div>
                      </div>
                      <el-button type="text">
                        {{ item.applyName }}
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="row.activityResultList.length > 2">
                  <div v-show="row.spread == false" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary" plain
                      >展开<el-icon class="el-icon-arrow-down"></el-icon
                    ></el-button>
                  </div>
                  <div v-show="row.spread == true" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary"
                      >收起<el-icon class="el-icon-arrow-up"></el-icon
                    ></el-button>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="170"
            label="咨询"
            key="specialistResults"
            prop="specialistResults"
            v-if="queryInfo.type == 3"
            align="center"
          >
            <template v-slot="{ row }">
              <div v-if="row.specialistResults">
                <div :class="[row.spread ? 'shareResultList' : 'spreadwrap']">
                  <div
                    class="tooltip_box"
                    v-for="(item, index) in row.specialistResults"
                    :key="index"
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        <div>{{ item.consultName }}</div>
                        <div style="margin-top: 5px">
                          <span>{{ item.position }}|{{ item.phone }}</span>
                        </div>
                      </div>
                      <el-button type="text">
                        {{ item.consultName }}
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="row.specialistResults.length > 2">
                  <div v-show="row.spread == false" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary" plain
                      >展开<el-icon class="el-icon-arrow-down"></el-icon
                    ></el-button>
                  </div>
                  <div v-show="row.spread == true" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary"
                      >收起<el-icon class="el-icon-arrow-up"></el-icon
                    ></el-button>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="170"
            label="下载"
            key="reportResultList"
            prop="reportResultList"
            v-if="queryInfo.type == 4"
            align="center"
          >
            <template v-slot="{ row }">
              <div
                v-if="row.reportResultList"
                :class="[row.spread ? 'shareResultList' : 'spreadwrap']"
              >
                <div class="tooltip_box" v-for="(item, index) in row.reportResultList" :key="index">
                  <el-tooltip placement="top">
                    <div slot="content">
                      <div>{{ item.uploadName }}</div>
                      <div style="margin-top: 5px">
                        <span>{{ item.position }}|{{ item.phone }}</span>
                      </div>
                    </div>
                    <el-button type="text">
                      {{ item.uploadName }}
                    </el-button>
                  </el-tooltip>
                </div>
              </div>

              <div v-if="row.reportResultList && row.reportResultList.length > 4">
                <div v-show="row.spread == false" @click="spreadText(row)" class="spread-btn">
                  <el-button size="mini" type="primary" plain
                    >展开<el-icon class="el-icon-arrow-down"></el-icon
                  ></el-button>
                </div>
                <div v-show="row.spread == true" @click="spreadText(row)" class="spread-btn">
                  <el-button size="mini" type="primary"
                    >收起<el-icon class="el-icon-arrow-up"></el-icon
                  ></el-button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="correlation" prop="correlation" label="关联度" align="center">
            <template v-slot="{ row }">
              <span style="color: #fd6161" v-if="row.correlation">{{ row.correlation }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="匹配类型" key="pushType" prop="pushType" align="center">
            <template v-slot="{ row }">
              <span v-if="!row.correlation">人工匹配</span>

              <el-tooltip v-else placement="top">
                <div slot="content">
                  <div>{{ row.userName }}</div>
                  <div style="padding: 10px 0px">
                    <span>版本号：{{ row.versionNumber }}</span>
                  </div>
                  <div>
                    <span>{{ row.pushType }}</span>
                  </div>
                </div>
                <el-button type="text"> 算法匹配 </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="推送人" prop="userName" key="userName" width="160" align="center">
            <template v-slot="{ row }">
              <span>{{ row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="推送时间"
            key="pushTime"
            align="center"
            prop="pushTime"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template v-slot="{ row }">
              <div class="btn">
                <span class="pass" @click="Jumpinfo(row)" style="margin-right: 20px">详情</span>
                <span class="pass" v-if="row.isShelf == 0" @click="isShelf(row)">下架</span>
                <span class="un_pass" v-if="row.isShelf == 1" @click="isShelf(row)">上架</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="Pagination">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  companyContentPage,
  getCompanyDetail,
  getTabloidContentNum,
  getCompanyContentNum
} from '@/api/datement'
import { pushedCompanyShelf } from '@/api/content.js'
import { getTabloid } from '@/api/weekly'
const defaultQueryInfo = Object.freeze({
  companyId: '',
  tabloidId: '',
  informationType: null, //资讯类别 null表示全部； 1应用场景，2数智技术，3行业动态，4数智案例，5数智产品，6服务商,7政策解读,8组织人才,9专家观点
  matchingType: null, //匹配类型 null表示全部 1是人工算法 2是匹配算法
  orderByParam: '', //排序字段+ DESC或ASC
  pageNum: 1,
  pageSize: 10,
  queryConditions: '', //模糊查询条件
  type: 1 //1 内容资讯； 2 数智活动；3 平台专家 4行业报告 ；5 数智问题
})
export default {
  name: 'EnterpriseContent',
  computed: {
    companyId() {
      return this.$route.query.id
    },

    tabloidId() {
      return this.$route.query.tabloidId
    }
  },
  data() {
    return {
      ids: [],
      idsList: [],
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      companyinfo: {}, //企业信息
      paramWeek: {}, //周报详情
      informationType: [
        {
          id: 1,
          val: '应用场景'
        },
        {
          id: 2,
          val: '数智技术'
        },
        {
          id: 3,
          val: '行业动态'
        },
        {
          id: 10,
          val: '数智案例'
        },
        {
          id: 5,
          val: '数智产品'
        },
        {
          id: 6,
          val: '服务商'
        },
        {
          id: 7,
          val: '政策解读'
        },
        {
          id: 8,
          val: '组织人才'
        },
        {
          id: 9,
          val: '专家观点'
        }
      ],
      //类型
      typeList: [
        {
          id: 1,
          name: '内容资讯',
          num: 0
        },
        {
          id: 2,
          name: '数智活动',
          num: 0
        },
        {
          id: 3,
          name: '平台专家',
          num: 0
        },
        {
          id: 4,
          name: '行业报告',
          num: 0
        }
        // {
        //   id: 5,
        //   name: '数智问题'
        // }
      ],
      indexType: 1,
      //实体数量
      contentNum: {},
      // 遮罩层
      loading: false,
      // 总条数
      total: 0
    }
  },
  created() {
    this.search()
    this.getcompany()
    this.getTabloid()
  },

  methods: {
    //查询企业信息回显
    async getcompany() {
      const { data: res } = await getCompanyDetail({ id: this.companyId })
      if (res.resultCode == 200) {
        this.companyinfo = res.data
      }
    },
    async getTabloid() {
      if (this.tabloidId) {
        //周报详情
        this.getTabloidContentNum()
        const { data: res } = await getTabloid({ id: this.tabloidId })
        if (res.resultCode === 200) {
          this.paramWeek = res.data
        }
      }
    },
    //实体数量
    async getCompanyContentNum() {
      const { data: res } = await getCompanyContentNum({ id: this.companyId })
      if (res.resultCode == 200) {
        this.contentNum = res.data
        this.typeList[0].num = this.contentNum.informationNum //资讯
        this.typeList[1].num = this.contentNum.activityNum
        this.typeList[2].num = this.contentNum.specialistNum
        this.typeList[3].num = this.contentNum.reportNum
        //  this.typeList[4].num = this.contentNum.questionNum
      }
    },
    //周报实体数量
    async getTabloidContentNum() {
      const query = {
        companyId: this.companyId,
        tabloidId: this.tabloidId
      }
      const { data: res } = await getTabloidContentNum(query)
      if (res.resultCode == 200) {
        this.contentNum = res.data
        this.typeList[0].num = this.contentNum.informationNum //资讯
        this.typeList[1].num = this.contentNum.activityNum
        this.typeList[2].num = this.contentNum.specialistNum
        this.typeList[3].num = this.contentNum.reportNum
        //  this.typeList[4].num = this.contentNum.questionNum
      }
    },

    //类型选择
    storeClick(id) {
      this.queryInfo.type = id
      this.indexType = id
      this.search()
    },
    //内容数据一览
    async search() {
      this.loading = true
      this.queryInfo.companyId = this.companyId
      if (this.tabloidId) {
        this.queryInfo.tabloidId = this.tabloidId
      } else {
        this.getCompanyContentNum()
      }
      const { data: res } = await companyContentPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.listData.forEach((el) => {
          if (el.labels) {
            el.labels = el.labels.split('~')
          }
          this.$set(el, 'spread', false)
        })

        this.total = res.data.total
        this.loading = false
      } else {
        this.loading = false
      }
    },
    //跳转详情
    Jumpinfo(row) {
      //'资讯'
      if (this.queryInfo.type == 1) {
        this.$router.push({
          path: '/content/redact',
          query: {
            id: row.foreignKey,
            headTabActive: 0
          }
        })
      }
      //活动
      if (this.queryInfo.type == 2) {
        this.$router.push({
          path: '/activity/editActivity',
          query: {
            id: row.foreignKey,
            headTabActive: 0
          }
        })
      }
      //专家
      if (this.queryInfo.type == 3) {
        this.$router.push({
          path: '/expert/addexpert',
          query: {
            id: row.foreignKey,
            headTabActive: 0
          }
        })
      }
      //报告
      if (this.queryInfo.type == 4) {
        this.$router.push({
          path: '/content/editReport',
          query: {
            id: row.foreignKey,
            headTabActive: 0
          }
        })
      }
    },
    //企业基本信息
    companyInfo() {
      this.$router.push({
        path: '/customer/addenterprise',
        query: { id: this.companyId, headTabActive: 1 }
      })
    },
    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.foreignKey)
    },
    //展开收缩
    spreadText(row) {
      row.spread = !row.spread
    },
    //单个上下架
    isShelf(row) {
      let idType = ''
      if (this.queryInfo.type == 1) {
        idType = '资讯'
      }
      if (this.queryInfo.type == 2) {
        idType = '活动'
      }
      if (this.queryInfo.type == 3) {
        idType = '专家'
      }
      if (this.queryInfo.type == 4) {
        idType = '报告'
      }
      const query = {
        companyIds: [this.companyId],
        id: row.foreignKey,
        idType: idType,
        isShelf: row.isShelf == 0 ? '1' : '0'
      }
      const that = this
      this.$alert(`是否确认${row.isShelf == 0 ? '下架' : '上架'}该企业？`)
        .then(function () {
          return pushedCompanyShelf(query)
        })
        .then(() => {
          this.search()
          this.$message.success(`${row.isShelf == 0 ? '下架' : '上架'}成功`)
        })
        .catch(() => {})
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__inner {
  border: 1px solid #fff;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}

.EnterpriseContent {
  .order_top {
    background: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .primary_buttom {
      border: 1px solid #448aff;
      background: #fff;
    }

    .buttom_info {
      padding-right: 30px;
    }
    .storeCenter_companyinfo_top_left {
      display: flex;
      flex-direction: row;

      img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        margin-right: 14px;
      }

      .storeCenter_companyinfo_top_left_flex {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .img_commer {
          display: flex;
          border: 1px solid #146aff;
          border-radius: 39px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #146aff;
          }
          .color_img {
            background: #146aff;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }
        .img_commers {
          display: flex;
          border: 1px solid #ff9c00;
          border-radius: 39px;
          margin: 0px 10px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #ff9c00;
          }
          .color_img {
            background: #ff9c00;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }

        h4 {
          color: #1f2d3d;
          font-size: 26px;
          font-weight: 500;
          margin: 0;
        }

        .storeCenter_companyinfo_top_left_flex_tag {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;
          position: relative;
          left: -5px;

          span {
            box-sizing: border-box;
            padding: 1px 6px;
            display: block;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
          }

          .office {
            border: 1px solid #559eff;
            color: #4e93fb;
            cursor: pointer;
          }

          .support {
            border: 1px solid #fd523f;
            color: #fd523f;
            margin-left: 12px;
          }
        }

        .address {
          display: flex;
          align-items: center;
          font-size: 14px;
          span {
            padding: 0px 10px;
          }
        }
      }
    }
  }
  .form_info {
    padding: 0px 0px 10px 0px;
    background: #fff;
    margin-top: 20px;

    .form {
      padding-top: 15px;
    }
    .store_table {
      padding-left: 10px;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      background: #fafafa;

      .store_table_item {
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0px 10px;
        margin-right: 30px;
        border-radius: 1px;
      }

      .active {
        padding: 0px 10px;
        color: #4e93fb;
        font-size: 16px;
        margin-right: 30px;
        border-radius: 1px;
        background: #ffffff;
      }
    }
    .pass {
      color: #4e93fb;
      cursor: pointer;
    }
    .un_pass {
      color: #fd6161;
      cursor: pointer;
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
    .shareResultList {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .tooltip_box {
      padding: 0px 5px;
      display: inline-block;
    }
    .spreadwrap {
      -webkit-line-clamp: 2; //设置几行
      display: -webkit-box; //设置为伸缩盒弹性盒子展示
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //设置超出部分以省略号展示
      -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
    }
    .span_tag {
      color: #4e93fb;
      cursor: pointer;
    }
    .input-with {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
      margin-left: 20px;
      .input-with-select {
        width: 400px;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
